<template>
  <div class="mainBox">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <van-notice-bar color="#1989fa" background="#ecf9ff">
      功能介绍：过滤掉不必要的号码，最大化投资回报率
    </van-notice-bar>
    <div class="mainInfoBox">
      <h5>请选择玩法</h5>
      <p>（大底：该玩法下的所有号码）</p>
      <br>
      <van-grid direction="horizontal" :column-num="2" :gutter="10">
        <van-grid-item @click="toPayWay(1)">
          <van-badge style="background-color: orange;border-radius: 10px">
            <h5>四定</h5>
          </van-badge>
          <p>大底:10000组</p>
        </van-grid-item>
        <van-grid-item @click="toPayWay(2)">
          <van-badge style="background-color: orange;border-radius: 10px">
            <h5>四现</h5>
          </van-badge>
          <p>大底:715组</p>
        </van-grid-item>
      </van-grid>
      <van-grid direction="horizontal" :column-num="2" :gutter="10">
        <van-grid-item @click="toPayWay(3)">
          <van-badge style="background-color: rebeccapurple;border-radius: 10px">
            <h5>三定</h5>
          </van-badge>
          <p>大底:4000组</p>
        </van-grid-item>
        <van-grid-item @click="toPayWay(4)">
          <van-badge style="background-color: rebeccapurple;border-radius: 10px">
            <h5>三现</h5>
          </van-badge>
          <p>大底:220组</p>
        </van-grid-item>
      </van-grid>
      <van-grid direction="horizontal" :column-num="2" :gutter="10">
        <van-grid-item @click="toPayWay(5)">
          <van-badge style="background-color: rgba(255,20,147,0.7);border-radius: 10px">
            <h5>二定</h5>
          </van-badge>
          <p>大底:600组</p>
        </van-grid-item>
        <van-grid-item @click="toPayWay(6)">
          <van-badge style="background-color: rgba(255,20,147,0.7);border-radius: 10px">
            <h5>二现</h5>
          </van-badge>
          <p>大底:55组</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils";
import config from "../../../package.json";
export default {
  components:{
    vHeader
  },
  name: "payWayIndex",
  data(){
    return{
      title: this.$route.meta.title,
    }
  },
  mounted:function(){
    if (this.$store.state.payWay.version !== config.version || this.$store.state.payWay.version === undefined){
      this.$store.commit('clearData');
      this.$store.commit('addVersion',config.version);
      location.reload();
    }
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }

  },
  methods:{
    //跳转参数 1.四定 2.四现 3.三定 4.三现 5.二定 6.二现
    toPayWay(par)
    {
      switch (par) {
        case 1:
          if (this.$store.state.payWay.fourDecNum.length === 0)
          {
            this.$store.commit('updateFourDec');
          }
          break;
        case 2:
          if (this.$store.state.payWay.fourShowNum.length === 0)
          {
            this.$store.commit('updateFourShow');
          }
          break;
        case 3:
          if (this.$store.state.payWay.threeDecNum.length === 0)
          {
            this.$store.commit('updateThreeDec');
          }
          break;
        case 4:
          if (this.$store.state.payWay.threeShowNum.length === 0)
          {
            this.$store.commit('updateThreeShow');
          }
          break;
        case 5:
          if (this.$store.state.payWay.twoDecNum.length === 0)
          {
            this.$store.commit('updateTwoDec');
          }
          break;
        case 6:
          if (this.$store.state.payWay.twoShowNum.length === 0)
          {
            this.$store.commit('updateTwoShow');
          }
          break;
      }
      this.$router.push({name:'payWay',params:{key:par}});
    }
  }
}
</script>

<style lang="less">
.mainBox{
  .mainInfoBox{
    height: 60%;
    width: 100%;
    padding: 0;
    margin: 0;

    h5{
      margin: 0;
      padding: 10px 10px;
      display: inline-block;
    }
    p{
      margin: 0;
      display: inline-block;
      font-size: x-small;
      color: #7a7a7a;
    }
    .van-grid{
      padding-bottom: 10px;

      .van-grid-item{
        .van-grid-item__content--center{
          justify-content: left;

          p{
            color: black;
            margin: 0px 5px;
            font-weight: bolder;
            font-size: 0.3rem;
          }
          h5{
            color: white;
            width: 1.3rem;
            font-size:0.5rem;

          }
        }
      }
    }
  }
}
</style>
